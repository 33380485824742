//
// Google font - IBM Plex Sans
//

// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');



@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body, html {
    font-family: 'Roboto', sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.font-weight-bold{
    font-weight: 500;
}